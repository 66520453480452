@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

@import url("https://use.typekit.net/jis5rux.css");

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

main{
  min-height:40rem;
}

html {
		font-size: $scale-base-size*$scale-ratio-xxs;
		@include media(xs) {
				font-size: $scale-base-size*$scale-ratio-xs;
		}
		@include media(sm) {
				font-size: $scale-base-size*$scale-ratio-sm;
		}
		@include media(md) {
				font-size: $scale-base-size*$scale-ratio-md;
		}
		@include media(lg) {
				font-size: $scale-base-size*$scale-ratio-lg;
		}
		@include media(xl) {
				font-size: $scale-base-size*$scale-ratio-xl;
		}
		@include media(xxl) {
				font-size: $scale-base-size*$scale-ratio-xxl;
		}
}

@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
       only screen and(      -o-min-device-pixel-ratio: 5/4 ),
       only screen and( min-resolution: 120dpi ),
       only screen and( min-resolution: 1.25dppx ) {
		html {
				font-size: $scale-base-size*$scale-ratio-xxs;
				@include media(xs) {
						font-size: $scale-base-size*$scale-ratio-xs;
				}
				@include media(sm) {
						font-size: $scale-base-size*$scale-ratio-sm;
				}
				@include media(md) {
						font-size: $scale-base-size*$scale-ratio-md;
				}
				@include media(lg) {
						font-size: $scale-base-size*$scale-ratio-lg;
				}
				@include media(xl) {
						font-size: $scale-base-size*$scale-ratio-xl*0.8;
				}
				@include media(xxl) {
						font-size: $scale-base-size*$scale-ratio-xxl*0.8;
				}
		}
}

form,
html,
body{
    font-family:$font-family;
    height:100%;
    color: $base-black;
}

body{
	 @include pux-scale-with-min("font-size", 18px, 15px);
}

h2,
.h2{
  @include pux-scale-with-min("font-size", 40px, 25px);
  line-height: 1.1;
  font-weight: 700;
  padding-bottom: 2rem;
  color: #2a2a2a;
}

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
    }
}

header{
  width: 100%;
  position: fixed;
  z-index: 77;
  &.scrolled{
    background-color: $base-white;
    box-shadow: 0 0 38px rgba(0, 0, 0, 0.23);
    .header{
      @include media(md){
        height: 6rem;
      }
      &-logo{
        @include media(md){
          @include transform(translateX(-12.5%) scale(0.75));
        }
      }
    }
  }
  .header{
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    @include transition;
    height: 12.5rem;
    &-logo{
      @include transition;
      a{
        display:inline-block;
        span{
          display:none;
        }
        img{
          @include transition;
          height: 5.1rem !important;
          width: 18.1rem;
        }
      }
    }
  }
}

footer{
  padding: 4rem 0;
  background: $second-color;
  .footer{
    color: $base-white;
    font-size: 14px;
    text-align: center;
    @include flex-block;
    @include justify-content(center);
    @include align-items(center);
    @include flex-wrap(wrap);
    @include media(lg){
      text-align: left;
      @include justify-content(space-between);
    }
    .footer-column{
      @include media(lg){
        max-width: 60%;
      }
      span{
        display: inline-block;
        margin: 0 .5rem;
      }
    }
    a:hover,
    a:focus{
      color: #fff;
    }
  }
}

.portal-container{
  padding: 8rem 15px;
  margin: 0 -15px;
  position: relative;
  @include media(md){
    margin: 0 -8.5rem;
    padding: 8rem 8.5rem;
  }
  .row{
    margin: 0 -2rem;
    [class^="col"]{
      padding: 4rem 2rem;
      @include media(lg){
        padding: 2rem;
      }
    }
  }
  .portal-item{
    &-ico{
      font-size: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      @include media(md){
        text-align: left;
      }
    }
    &-title{
      @include pux-scale-with-min("font-size", 25px, 18px);
      //@include pux-scale-with-min("line-height", 55px, 36px);
      font-weight: 700;
      padding-top: 4rem;
      padding-bottom: 1rem;
    }
    &-text{
      font-size: 16px;
      line-height: 1.25;
    }
  }
}

a{
    color: $color-link;
    cursor: pointer;
    @include transition;
    &:focus,
    &:hover{
      color: $base-black;
      outline:none;
      text-decoration: none;
    }
}

.btn.btn-primary{
  display: inline-block;
  @include border-radius(3px);
  border: none;
  padding: 1rem 2rem;
  @include box-shadow(none);
  font-size: 16px;
  line-height: 26px;
  background: $main-color;
  color: $base-white;
  @include flex-block;
  @include justify-content(center);
  min-width: 120px;
  letter-spacing: 0.16px;
  &:focus,
  &:hover{
      text-decoration: none;
      @include box-shadow(none);
      background: $base-black;
  }
}

.grey-bgr{
  padding: 15rem 0 8.5rem;
  background: $base-grey;
  .row.log{
    margin: 3.5rem -1.5rem 0;
    [class^="col"]{
      padding: 1.5rem;
      @include flex-block;
    }
    .log-item{
      width: 100%;
      background: $second-color;
      padding: 4rem 5rem 5rem;
      color: $base-white;
      @include pux-scale-with-min("font-size", 18px, 15px);
      h1,h2,h3,h4{
        color: $base-white;
      }
      &-title{
        @include pux-scale-with-min("font-size", 35px, 24px);
        @include pux-scale-with-min("line-height", 55px, 36px);
        font-weight: 700;
        padding-bottom: 1rem;
      }
      &-btn{
        padding-top: 3rem;
        .btn{
          display: inline-block;
        }
      }
    }
  }
  .row.info{
    margin: 0 -1.5rem;
    [class^="col"]{
      padding: 1.5rem;
      @include flex-block;
    }
    a{
      color: $base-black;
      &:hover,
      &:focus{
        color: $base-white;
        background: $second-color;
        .info-item{
          &-title{
            color: $main-color;
          }
          &-ref{
            color: $base-white;
            &:after{
              color: $base-white;
              @include transform(translateX(2px));
            }
          }
        }
      }
    }
    .info-item{
      padding: 3.5rem 5rem 4rem;
      background: $base-white;
      width: 100%;
      height: 100%;
      @include transition;
      &-title{
        @include pux-scale-with-min("font-size", 25px, 18px);
        font-weight: 700;
        letter-spacing: -0.25px;
        line-height: 1.2;
        padding-bottom: 3rem;
        @include transition;
      }
      &-ref{
        padding-top: 2rem;
        @include flex-block;
        @include align-items(center);
        @include transition;
        &:after{
          content: "\e919";
            font-weight: 700;
            font-size: 11px;
            @include transition;
            padding-left: 15px;
        }
      }
    }
  }
}
